* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
 }
 
 .container-fluid {
    padding: 0em;
 }

/* html {
    height: 100vh;
    
  } */
/* body {
    min-height: 95vh;
  } */

  html, body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}
  a {
    text-decoration: none;
    display: block;
    color: #000000;
  }

  h1, h3 {
    text-align: center;
  }
  h2 {
    color:  rgba(0,0,0,.55);;
    /* margin: 2em; */
    text-align: center;
    font-family: 'Playfair Display', serif;
    /* font-family: 'The Nautigal', cursive; */
  }

  .nav-link {
    margin: 0 1em 0 .5em;
    border-radius: 5px;
    font-size: 1.05rem;
    font-weight: 500;
    color: black;
  }
  .nav-link:hover {
    color: #fff;
    background-color:rgb(198, 198, 206);
    /* padding: 0.5em; */
  }
  @media (max-width: 768px) {
    h2 {
      color:  rgba(0,0,0,.55);
      font-size: 1.2em;
      /* margin: 2em; */
      text-align: center;
      font-family: 'Playfair Display', serif;
      /* font-family: 'The Nautigal', cursive; */
    }
  }
  @media (min-width: 769px) and (max-width: 1340px) {
    .nav-link {
      font-size: 0.9rem;
      font-weight: 500;
      color: black;
    }

  }

  .offcanvas-end {
    width: 250px;
    height: fit-content;
  }

    /* **************************************** */
  /* estilos para la seccion del Redes Sociales */
  /* ****************************************** */

  .redes-sociales {
    /* background: #1e2b38; */
    padding: 5px 0;
}

a {
    text-decoration: none;
}

.redes-sociales .contenedor {
    display: flex;
    justify-content: center;
}

.redes-sociales a {
    color: #fff;
    text-align: center;
    /* height: 1.5em; */
    width: 1.1em;
    display: block;
    padding: 5px 0;
    border-radius: 3px;
    font-size: 30px;
    margin: 0 10px;
}

.redes-sociales .twitter {
    background: #1da1f2;
}
.redes-sociales .facebook {
    background: #3b5998;
}
.redes-sociales .messenger {
    background: #00b2ff;
}
.redes-sociales .whatsapp {
    background: #128c7e;
}
.redes-sociales .instagram {
    background: #f56040;
}
.redes-sociales .linkedln {
    background: #0e76a8;
}

.redes-sociales .twitter:hover {
    border-radius: 50%;
    color: white;
}
.redes-sociales .facebook:hover {
    border-radius: 50%;
    color: white;
}
.redes-sociales .messenger:hover {
    border-radius: 50%;
    color: white;
}
.redes-sociales .whatsapp:hover {
    border-radius: 50%;
    color: white;
}
.redes-sociales .instagram:hover {
    border-radius: 50%;
    color: white;
}
.redes-sociales .linkedln:hover {
    border-radius: 50%;
    color: white;
}

  /* ************************************ */
  /* estilos para la seccion del header */
  /* ************************************ */
/* .header {
    height: 12vh;
} */

header {
  height: 107px;
}
.headerlogo {
  height: 4em;
  padding: 5px;
}
.header-name-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header-user-nane {
  font-size: .9em;
  color: rgba(0,0,0,.55) ;
}
.header-user-pic {
  height: 3.5em;
  width: 3.5em;
  border-radius: 50%;
  object-fit: cover;
  
}

.alert-fixed {
  position:fixed; 
  top: 0px; 
  left: 3%; 
  width: 100%;
  z-index:9999; 
  border-radius:0px
}


@media (max-width: 768px) {
  .navbar-brand, .nav-link {
    margin-right: 0%;
  }
  .headerlogo {
    height: 2.5em;
    padding: 5px;
  }
  .header-user-nane {
    font-size: .7em;
  }
  .header-user-pic {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    object-fit: cover;
  }

  .headerMenulink{
    font-size: .9em;
  }
  .headerlink {
    padding: 0%;
    font-size: .7em;
  }
}

  /* ************************************ */
  /* estilos para la seccion del categorias */
  /* ************************************ */

  .form-control:focus {
    background : rgb(239, 239, 232);
    color:blue;
    font-weight: bold;
    caret-color: red;
}

  /* ************************************ */
  /* estilos para la seccion del Main */
  /* ************************************ */

/* .main {
    min-height: 83vh;
} */
main {
  min-height: 83vh;
  flex: auto;
  padding-bottom: 1.5em;
  /* background-color: #ccc; */
}


.notfoundmain{
  min-height: 83vh;
  background-color: rgb(198, 198, 206);;
}
.notfoundh1 {
  padding-top: 2.5em;
  font-size: 5em;
}
.notfoundp {
  font-size: 4em;
}

.signinbody{
  padding: 0 2em 0 2em ;
  justify-content: center;
}
.signinforgatPasswordLink {
  font-weight: 500;
  font-size: 1em;
}
.signinforgatPasswordLink:hover {
  font-size: 1.2em;
}

  /* ************************************ */
  /* estilos para la seccion del profile */
  /* ************************************ */
.profileImage {
  width: 8em;
  /* margin: auto; */
}
.profile-user-pic {
  height: 6em;
  width: 6em;
  border-radius: 50%;
  object-fit: cover;
}
.profDivPics{
  display: flex;
  justify-content:space-evenly ;
}
.profDivPic{
  display: flex;
  justify-content:center ;
}

  /* ************************************ */
  /* estilos para la seccion del catalago */
  /* ************************************ */

  .titlecategoria {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #7ad981;
    color: #0d1957;
}
.catalagoli {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #383844;
  padding: 10px;
  border-bottom: solid 1px #edeaea
}

.catalagoli:nth-of-type(odd) {
  background: #edeaea;
};
.catalagoi {
  border-radius: 5px;
  border: solid 1px gray;
  padding: 4px 8px;
  color: green;
  cursor: pointer;
}

.list-catalago-pic {
  height: 3em;
  width: 5em;

  object-fit: fill;
}
.list-clientes-pic {
  height: 3em;
  width: 5em;
  object-fit: fill;
}

.profile-cuenta-pic {
  height: 6em;
  width: 16em;
  object-fit: fill;
}
.catalagoListP {
  font-size: .9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1000px;
}

.form-control {
  margin-bottom: .5em;
}
@media (max-width: 1450px)  and (min-width: 769px){
  .catalagoListP {
    font-size: .9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }
}

@media (max-width: 768px) {
  .catalagoListP {
    font-size: .7em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 170px;
  }
  .list-catalago-pic {
    display: none;
  }
  .titlecategoria {
    font-size: 1em;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #7ad981;
    color: #0d1957;
}

}

  /* ******************************************* */
  /* estilos para la seccion del InfoPreciosDisp */
  /* ******************************************* */

  .infoPreDispP {
    font-size: 1em;
  }

  .catalagoliextra {
    font-size: 1em;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  
  @media (max-width: 768px) {
    .infoPreDispP {
      margin: auto;
      font-size: .7em;
    }
    .catalagoliextra {
      margin: auto;
      font-size: .7em;
    }

  }

    /* ******************************************* */
  /* estilos para la seccion del Pagina de Inicio */
  /* ******************************************* */
.carousel-indicators {
    display: none;
}

.carousel-control-next {
    display: none;
}
.carousel-control-prev {
    display: none;
}

.carimg {
  width: 9em;
}

.inicioRow {
  display: flex;
  justify-content: space-evenly;
}


.activeCategoria {
  pointer-events: none;
  width: 18em;
  font-size: 1.1em  ;
  margin: 1em;
  font-family: 'Playfair Display', serif;

}
.leftcategoria {
  width: 17em;
  margin-right: 1em;
  font-size:  .7em;
}

.rightCategoria {
  width: 17em;
  margin-left: 1em;
  font-size:  .7em;
}

  .catdiv{
    display: flex;
    justify-content: space-evenly;
  }
  .inicioPagination {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
  }
  .CuentaCard {
    padding: 2em;
  }

  .cuentaImageLink{
    display: flex;
  }

  .cuentaImageCard{
    max-height: 11em;
    object-fit: fill ;

}

.clientesRow{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.clienteImg {
  width: 7em;
  max-height: 6.5em;
  object-fit: fill ;
}
.cuentaP {
  margin-bottom: 0;
  
}
.cuentaP1 {
  font-size: .8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 2600px;
}



  @media (max-width: 1100px)  and (min-width: 769px){
    .CuentaCard {
      /* margin: 2em; */
      padding: .5em;
    }
    .clienteImg {
      width: 6em;
      max-height: 6em;
        object-fit: fill ;
    }
    .cuentaP1 {
      font-size: .7em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 1200px;
    }
  }
  @media (max-width: 768px) {
    .card-body {
      padding: 0rem 1rem;
      width: 100%;
    }

    .CuentaCard {
      display: flex;
      flex-direction: inherit;
      align-items: center;
      margin: .4em .2em;
      padding: .2em;
    }
    .clienteImg {
      width: 3.5em;
      max-height: 4em;
        object-fit: fill ;
    }
    .cuentaImageLink{
      min-width: 5em;
      max-width: 6.1em;
      width: 6em;
      object-fit: cover ;
      /* display: none; */
  }
  .carimg {
    height: 3.5em;
    width: 8em;
  }

  .activeCategoria {
    width: 17em;
    font-size: .6em  ;
    margin: .4em;
  }
  .leftcategoria {
    width: auto;
    margin-right: 1.5em;
    font-size:  .5em;
  }
  
  .rightCategoria {
    width: 17em;
    margin-left: 1.5em;
    font-size:  .6em;
  }
  .cuentaP1 {
    font-size: .6em;
    white-space: break-spaces;

  }
  .cuentaP {
    font-size: .8em;
    white-space: break-spaces;

  }

  }

  /* *********************************************** */
  /* estilos para la seccion del Pagina CuentaScreen */
  /* *********************************************** */

.cuentaScreenCol{
  display: flex;
  justify-content: center;
}

.cuentaScreenImg {
  min-height: 23em;
  max-height: 31em;
  width: 70em;
  padding: 1em ;
}
.cuentaScreenRev {
  min-height: 13em;
}
.cuentaScreenBtn {
  color: #000000;
  width: 18em;
  font-family: 'Playfair Display', serif;
  font-size: 1.2em
}
.cuentaCarouselComent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media (max-width: 768px) {
  .cuentaScreenCol{
    flex-direction: column;
  }
  .cuentaScreendiv {
    padding: 1em ;
  }
  .cuentaScreenImg {
    min-height: 11em;
    max-height: 18em;
    width: 45em;
    padding: 1em ;
  }
  .cuentaScreenBtn {
    width: 17em;
    font-size: .7em
  }
  .cuentaScreenRev {
    min-height: 8em;
  }
}

.showpicures{
  max-width: 12em;
  display: flex;
}
.imagesDiv {
  display: flex;
  flex-direction: column;
}
.blink {
  animation: blinker 1s step-start infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (max-width: 1100px)  and (min-width: 769px){
  .cuentaScreenImg {
    min-height: 11em;
    max-height: 18em;
    width: 50em;
    padding: 1em ;
  }
  .cuentaScreenRev {
    min-height: 20em;
  }
}


  /* *********************************************** */
  /* estilos para la seccion del Pagina Add Reviews */
  /* *********************************************** */

.addReviewsDiv {
  width: 70%;
  margin: 6em auto .5em;
}

@media (max-width: 768px) {
  .addReviewsDiv {
    width: 100%;
    margin: 6em auto;
  }
}

  /* *********************************************** */
  /* estilos para la seccion del Pagina Testimonios */
  /* *********************************************** */
.TestimonioCard {
  min-height: 22em;
  border-radius: 20%;
  /* border: none; */
}
.testiCardBody{
  padding: 0%;
  flex: inherit;
}

.testimoniosRow {
  display: flex;
  justify-content: space-around;
  margin: 2em 0;
}
.testImage {
  margin: .5em 0;
  height: 5em;
  width: 5em;
  border-radius: 50%;
  object-fit: fill;
}
.testCompany{
  font-family: 'Playfair Display', serif;
  margin:  0 1.5em;
  font-size: .9em;
}

.testimoniosComent {
  margin: 1.5em 1em;
  font-size: 1.2em;
  white-space: wrap;
  overflow: auto;
  text-overflow: ellipsis;
  max-height: 150px;
}


@media (max-width: 768px) {
  .testimoniosComent {
    margin: 1.5em 1em;
    font-size: .8em;
    white-space: wrap;
    overflow: auto;
    text-overflow: ellipsis;
    max-height: 150px;
  }
}

@media (max-width: 1100px)  and (min-width: 769px){
  .testimoniosComent {
    margin: 1.5em 1em;
    font-size: 1em;
    white-space: wrap;
    overflow: auto;
    text-overflow: ellipsis;
    max-height: 150px;
  }
}
    /* ************************************ */
  /* estilos para la seccion del Footer */
  /* ************************************ */

/* .footer {
    height: 8vh;
    display: flex;
    align-items: center;
} */
.footer {

  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footerP {
  margin: auto;
  font-size: 1em;
}
.footerP2 {
  margin: auto;
  font-size: .8em;
}

@media (max-width: 768px) {
  .footerP {
    margin: auto;
    font-size: .9em;
  }
  .footerP2 {
    margin: auto;
    font-size: .7em;
  }
}